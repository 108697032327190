import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import NumberFormat from 'react-number-format';
import AdminContainer from '../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import Heading from '../../../layout/admin/heading';
import { getApiRequest } from '../../../../utils/request';
import { isBrowser, capFirstLetter, formatDay } from '../../../../utils/helpers';
import Loadable from '../../../data/loadable';
import AdminTable from '../admin/common/admin-table';
import AutoForm from '../../../form/auto-form';
import { getCenterAdminToken } from '../../../../utils/auth';
import Badge from '../../../badges/badge';
import SEO from '../../../layout/seo';


function formatRow(item) {
  const {id, price, min_age: minAge, start_date: startDate, status, name, league, total_stock: total, purchased } = item;
  return {
    name: <span><span className="font-bold">{name}</span><br /><span className="font-bold">League:</span>{league.name}</span>,
    start_date: formatDay(startDate, 'M/D/Y'),
    stock: <span>Total: <span className="font-bold">{total}</span><br />Purchased: <span className="font-bold">{purchased}</span></span>,
    price: <NumberFormat value={price} displayType="text" thousandSeparator prefix="$" />,
    min_age: minAge,
    purchased,
    status: status === 'active' ? <Badge text={capFirstLetter(status)} color="green" /> : <Badge text={capFirstLetter(status)} color="yellow" />,
    id,
  };
}

const CenterAdminProducts = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/center-admin/products',
        label: 'Products',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadProducts = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/center-admin/products${queryParamsString ? `?${queryParamsString}&password=${getCenterAdminToken()}` : `?password=${getCenterAdminToken()}`}`);
    // setProducts(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadProducts();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'start_date',
      label: 'Start Date',
      isBold: true,
    },
    {
      key: 'stock',
      label: 'Inventory',
    },
    {
      key: 'price',
      label: 'Price',
    },
    {
      key: 'min_age',
      label: 'Min Age',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <span>

          <Link
            to={`/app/center-admin/product/view?id=${row.id}`}
            className="inline-flex items-center mr-2 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            View
          </Link>

          {/* <Link
            to={`/app/center-admin/product/edit?id=${row.id}`}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Edit
          </Link> */}

          {row.purchased > 0 && (
            <Link
              to={`/app/center-admin/registrations?product_id=${row.id}`}
              className="inline-flex items-center ml-2 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Registrations
            </Link>
          )}
        </span>
      ),
    },
  ];

  const actionLinks = [
    {
      label: 'Create Product',
      href: '/app/center-admin/products/choose-league',
    },
  ];

  const baseUrl = '/app/center-admin/products';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/center-admin/products',
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
      },
      {
        name: 'league_id',
        label: 'League',
        type: 'select',
        optionsUrl: `/options/leagues?password=${getCenterAdminToken()}`,
      },
      {
        name: 'start_date',
        label: 'Start Date',
        type: 'date',
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'pending',
            label: 'Pending',
          },
          {
            value: 'active',
            label: 'Active',
          },
        ],
      },
    ],
    submitLabel: 'Search Products',
  };

  return (
    <AdminContainer centerAdmin>
      <SEO title="Products" />
      <Heading title="Products" links={actionLinks} />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Products" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default CenterAdminProducts;
